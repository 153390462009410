import React, { useState, useEffect } from 'react';
import { ScheduleCourseDto, WeekDay, InterLiftSchedule } from '../../models/interlift/interlift_schedule';
import { api } from "../../shared/axios_wrapper";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ScheduleForm from './schedule_form';
import ScheduleModal from './schedule_modal';
import { useAuth } from '../../app/context/auth_provider';
import { UserRole } from '../../models/user/user';
import { DocumentTitle } from '../../app/components/document_title';
import ScheduleTable from './schedule_table';
import ConfirmDeleteModal from './confirm_schedule_delete_modal';
import { Result } from '../../models/common/paged_result';

function InterLiftScheduleForm() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [programName, setProgramName] = useState<string>('inter-lift');
  const [weekBeginDate, setWeekBeginDate] = useState<string>('');
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [interLiftCourses, setInterLiftCourses] = useState<ScheduleCourseDto[]>([]);
  const [selCourseDto, setSelCourseDto] = useState<ScheduleCourseDto | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<WeekDay | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
  const [schedule, setSchedule] = useState<Map<WeekDay, Map<string, ScheduleCourseDto>>>(initializeSchedule());
  const [submitting, setSubmitting] = useState<boolean>(false);

  function initializeSchedule(): Map<WeekDay, Map<string, ScheduleCourseDto>> {
    const schedule = new Map<WeekDay, Map<string, ScheduleCourseDto>>();
    const days = Object.values(WeekDay).filter((day) => typeof day === 'number') as WeekDay[];

    days.forEach((day) => {
      schedule.set(day, new Map<string, ScheduleCourseDto>());
    });

    return schedule;
  }

  const generateNextSlot = (daySchedule: Map<string, ScheduleCourseDto>): string | null => {
    const usedSlots = Array.from(daySchedule.keys());
    const availableSlots = Array.from({ length: 6 }, (_, i) => `Education Hour ${i + 1}`);
    return availableSlots.find(slot => !usedSlots.includes(slot)) || null;
  };

  useEffect(() => {
    if (user?.role === UserRole.SuperAdmin) {
      setLoading(true);
      api.get<Result<ScheduleCourseDto[]>>('interlift/get-course-module-list')
        .then(r => {
          if (r?.data?.result) {
            setInterLiftCourses(r.data.result);
          }
        })
        .catch(() => {
          toast.error("Error fetching InterLift Courses", { theme: "colored" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user?.accountId, user?.role]);


  const handleAddSlot = (day: WeekDay) => {
    setSelectedDay(day);
    const daySchedule = schedule.get(day) || new Map();
    const nextSlot = generateNextSlot(daySchedule);
    if (nextSlot) {
      setSelectedSlot(nextSlot);
      setSelCourseDto(null); // Open modal with an empty slot
      setModalOpen(true);
    } else {
      toast.info("All slots are filled for this day.");
    }
  };

  const handleSave = (updatedCourse: ScheduleCourseDto) => {
    if (selectedDay && selectedSlot) {
      setSchedule(prev => {
        const newSchedule = new Map(prev);
        const daySchedule = new Map(newSchedule.get(selectedDay) || new Map());
        daySchedule.set(selectedSlot, updatedCourse);
        newSchedule.set(selectedDay, daySchedule);
        return newSchedule;
      });

      setModalOpen(false);
      setSelCourseDto(null);
    }
  };

  const handleEditLinkClick = (day: WeekDay, slot: string) => {
    const daySchedule = schedule.get(day);
    const courseModule = daySchedule?.get(slot) || null;

    if (courseModule) {
      setSelectedDay(day);
      setSelectedSlot(slot);
      setSelCourseDto(courseModule);
      setModalOpen(true);
    }
  };

  const handleDeleteLinkClick = (day: WeekDay, slot: string) => {
    setSelectedDay(day);
    setSelectedSlot(slot);
    setConfirmDeleteOpen(true);
  };

  const handleDelete = () => {
    if (selectedDay && selectedSlot) {
      setSchedule(prev => {
        const newSchedule = new Map(prev);
        const daySchedule = new Map(newSchedule.get(selectedDay) || new Map());
        daySchedule.delete(selectedSlot);
        newSchedule.set(selectedDay, daySchedule);
        return newSchedule;
      });
      setConfirmDeleteOpen(false);
    }
  };

  const isValidWeekBeginDate = (date: string): boolean => {
    try {
      const weekBeginDate = new Date(`${date}T00:00:00Z`);
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      const oneYearFutureDate = new Date(currentDate);
      oneYearFutureDate.setUTCFullYear(currentDate.getUTCFullYear() + 1);
      const isMonday = weekBeginDate.getUTCDay() === 1;

      const isFutureDate = weekBeginDate > currentDate;
      const isWithinOneYear = weekBeginDate <= oneYearFutureDate;

      return isMonday && isFutureDate && isWithinOneYear;
    } catch (error) {
      console.error('Error parsing date:', error);
      return false;
    }
  };

  const getJustDate = (date: string): Date => {
    const weekBeginDate = new Date(`${date}T00:00:00Z`);
    return weekBeginDate;
  }

  const handleSubmit = () => {
    setFormErrors([]);
    const errors: string[] = [];

    if (!programName || programName.trim() === "") {
      errors.push("Program Name is required.");
    }

    if (!isValidWeekBeginDate(weekBeginDate)) {
      errors.push("Week Begin Date must be a a future Monday within a year from today");
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    const courses: ScheduleCourseDto[] = [];
    schedule.forEach((daySchedule, day) => {
      daySchedule.forEach((course, slot) => {
        if (course.courseName) {
          courses.push({ ...course, day, slot });
        }
      });
    });

    if (courses.length === 0) {
      errors.push("Make sure to have at least one slot filled in");
    }

    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }

    const interLiftSchedule: InterLiftSchedule = {
      programName,
      weekBeginDate: getJustDate(weekBeginDate),
      courses,
    };

    setSubmitting(true);
    api.post('interlift/generate-schedule-package', interLiftSchedule)
      .then(() => {
        toast.success("Schedule package generated successfully!");
        navigate('/admin');
      })
      .catch(() => {
        toast.error("Error submitting schedule.", { theme: "colored" });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <DocumentTitle title="InterLift Schedule Packager" />
      <h2>InterLift Schedule Packager</h2>

      {loading ? (
        <div className="loading-indicator">
          <p>Loading...</p>
        </div>
      ) : (
        <>
          {formErrors.length > 0 && (
            <div className="error-messages">
              {formErrors.map((error, index) => (
                <p key={index} style={{ color: 'red' }}>{error}</p>
              ))}
            </div>
          )}

          <ScheduleForm
            programName={programName}
            weekBeginDate={weekBeginDate}
            submitting={loading || submitting}
            handleSubmit={handleSubmit}
            handleProgramNameChange={(e) => setProgramName(e.target.value)}
            handleDateChange={(e) => setWeekBeginDate(e.target.value)}
          />

        <ScheduleTable
          days={Array.from(schedule.keys())}
          schedule={schedule}
          handleEditLinkClick={handleEditLinkClick}
          handleDeleteLinkClick={handleDeleteLinkClick}
          handleAddSlot={handleAddSlot}
        />


          {modalOpen && (
            <ScheduleModal
              open={modalOpen}
              slot={selCourseDto || {
                day: WeekDay.Monday,
                slot: '',
                courseName: '',
                moduleCode: '',
                moduleName: '',
                modulePart: undefined,
                scheduledCode: '',
                includeQuiz: true,
                includeNRMaterial: true,
              }}
              isEditing={!!selCourseDto}  // Add this line to pass isEditing prop
              onSave={handleSave}
              onClose={() => setModalOpen(false)}
              existingSlots={Array.from(schedule.get(selectedDay as WeekDay)?.values() || [])}
              courseModules={interLiftCourses}
              selectedDay={selectedDay}
              selectedSlot={selectedSlot}
          />
          )}

          {confirmDeleteOpen && (
            <ConfirmDeleteModal
              confirmDeleteOpen={confirmDeleteOpen}
              setConfirmDeleteOpen={setConfirmDeleteOpen}
              handleConfirmDelete={handleDelete}
            />
          )}
        </>
      )}
    </>
  );
}

export default InterLiftScheduleForm;
