import React, { useState } from 'react';
import { Accordion, Icon, Button, Card } from 'semantic-ui-react';
import { ScheduleCourseDto, WeekDay } from '../../models/interlift/interlift_schedule';

type ScheduleTableProps = {
  days: WeekDay[];
  schedule: Map<WeekDay, Map<string, ScheduleCourseDto>>;  // Map of the schedule for each day
  handleEditLinkClick: (day: WeekDay, slot: string) => void;  // Handles editing of slots
  handleDeleteLinkClick: (day: WeekDay, slot: string) => void;  // Handles deletion of slots
  handleAddSlot: (day: WeekDay) => void;  // Handles adding of new slots
};


const ScheduleTable: React.FC<ScheduleTableProps> = ({
  days,
  schedule,
  handleEditLinkClick,
  handleDeleteLinkClick,
  handleAddSlot,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(-1);

  const handleAccordionClick = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const getFilledSlotsCount = (daySchedule: Map<string, ScheduleCourseDto> | undefined): number => {
    if (!daySchedule) return 0;
    return Array.from(daySchedule.values()).filter(course => course && course.courseName).length;
  };

  return (
    <Accordion styled fluid exclusive={false}>
      {days.map((day, index) => {
        const dayName = WeekDay[day];
        const daySchedule = schedule.get(day);  // Get the schedule for the specific day
        const filledSlots = getFilledSlotsCount(daySchedule);

        return (
          <React.Fragment key={dayName}>
            <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={() => handleAccordionClick(index)}
            >
              <Icon name="dropdown" />
              {`${dayName} (${filledSlots}/6)`} {/* Display the number of filled slots */}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === index}>
              <Card.Group itemsPerRow={3}>
                {/* Iterate over existing slots for the day */}
                {Array.from(daySchedule?.entries() || []).map(([slot, course], slotIndex) => (
                  <Card fluid key={slotIndex}>
                    <Card.Content>
                      <Card.Header>Education Hour {slotIndex + 1}</Card.Header>
                      <Card.Description>
                        <strong>Course:</strong> {course.courseName} <br />
                        <strong>Module:</strong> {course.scheduledCode} - {course.moduleName}  <br />
                        <strong>Quiz:</strong> {(course.includeQuiz === true) ? "YES": "No"} &nbsp;&nbsp;
                        <strong>NR:</strong> {(course.includeNRMaterial === true) ? "YES": "No"}
                        <br/><br/>
                        <Button onClick={() => handleEditLinkClick(day, slot)}>Edit</Button>
                        <Button onClick={() => handleDeleteLinkClick(day, slot)} color="red">Delete</Button>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
                <br/>
              <Button onClick={() => handleAddSlot(day)}>Add Slot</Button>
            </Accordion.Content>
          </React.Fragment>
        );
      })}
    </Accordion>
  );
};

export default ScheduleTable;
